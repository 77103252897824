import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate, Link } from 'gatsby';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';

import { PAGES } from 'config';
import { BookingLayout } from 'containers';
import { BookingHeader } from 'layouts';
import {
  BookingSummary, BookingToolbar, BookingActivity, withFormGroup, FormSelect, BookingEssential, FormInput, FormLabel
} from 'components';
import { GET_BOOKING, UPDATE_BOOKING } from 'actions/booking';
import { mapStateToProps } from 'utilities/state';
import {
  FORMAT_GRAPH_DATA,
  DAYS_BETWEEN,
  DATE_IN_BETWEEN,
  daysFromNow,
} from 'utilities/helpers';
// import { FORMAT_GRAPH_DATA, DAYS_BETWEEN } from 'utilities/helpers';
import { GET_FORMATTED_ACTIVITIES, GET_FORMATTED_ESSENTIALS } from 'utilities/booking/add-ons';

const Select = withFormGroup(FormSelect);

const FIELDS = {
  TourCategory: {
    ID: 'TourCategory',
    MAP: 'addon_category_ids',
  },
  TourLocation: {
    ID: 'TourLocation',
    MAP: 'location',
  },
  TourDays: {
    ID: 'TourDays',
    MAP: 'booking_tour_day_numbers',
  },
};

class BookingsAddOnsPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        [FIELDS.TourCategory.ID]: '',
        [FIELDS.TourLocation.ID]: '',
        [FIELDS.TourDays.ID]: '',
      },
      travellers: [],
      addOns: null, // populated from booking initially, then modified
      tours: FORMAT_GRAPH_DATA(props.data.allTourPages),
      formattedActivities: [],
      formattedEssentials: [],
      loading: true,
      updatingAddon: false,
      // showAllActivities: false,
      activities: {
        categories: FORMAT_GRAPH_DATA(props.data.allActivityCategories),
        activities: FORMAT_GRAPH_DATA(props.data.allActivityPages),
        images: props.data.allActivityImages,
      },
      saving: false,
    };
    this.handleSave = this.handleSave.bind(this);
    this.updateAddOns = this.updateAddOns.bind(this);
    this.updateEssential = this.updateEssential.bind(this);
    this.updateTravellers = this.updateTravellers.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  componentDidMount() {
    const { booking } = this.props;
    if (booking.travellers.length) {
      this.setState({
        travellers: booking.travellers,
      });
    }
    if (booking.currentBooking) {
      this.getBookingTour();
    }
    if (booking.addOns) {
      this.setState({
        addOns: booking.addOns,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { booking } = this.props;
    // if we have got travellers now
    if ((prevProps.booking.travellers !== booking.travellers) && booking.travellers) {
      this.setState({ // eslint-disable-line
        travellers: booking.travellers,
      });
    }
    // if we have now got a booking
    if (!prevProps.booking.currentBooking && booking.currentBooking) {
      this.getBookingTour();
    }
    // assume add ons have been updated
    if (prevProps.booking.addOns.length !== booking.addOns.length) {
      this.setState({ // eslint-disable-line
        addOns: booking.addOns,
      });
    }
  }

  /**
   * On change event
   */
  onFilterChange(value) {
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        ...value,
      },
    }));
  }

  /**
   * Find tour for this booking & format/filter activities based on that
   */
  getBookingTour() {
    const { booking } = this.props;
    const { tours, activities } = this.state;
    if (!tours) return;
    const MATCH = tours.find(tour => tour.api_id === booking.currentBooking.tour_id);
    if (MATCH) {
      let FORMATTED_ACTS = GET_FORMATTED_ACTIVITIES(activities.activities, activities.images.edges, MATCH.days);
      if (booking.currentBooking.is_reverse) {
        FORMATTED_ACTS = GET_FORMATTED_ACTIVITIES(activities.activities, activities.images.edges, MATCH.reverse_days);
      }
      const FORMATTED_ESSENTIALS = GET_FORMATTED_ESSENTIALS(activities.activities, activities.images.edges, MATCH.optional_addon_ids);
      this.setState({
        formattedActivities: FORMATTED_ACTS,
        formattedEssentials: FORMATTED_ESSENTIALS,
        loading: false,
      });
    }
  }

  getUpdateEssentials(addOns, data, destroy) { // eslint-disable-line
    const ADD_ON_INDEX = addOns
      .findIndex(addOn => addOn.db_addon_id === data.addOnID
        && addOn.option_id === data.optionID
        && addOn.traveller_id === data.travellerID);

    // Create a new add-on
    const ADD_ON = {
      db_addon_id: data.addOnID,
      option_id: data.optionID,
      traveller_id: data.travellerID,
      _destroy: destroy,
    };

    const UPDATED_ADDONS = [
      ...addOns,
    ];

    // Find or replace the add-on
    if (ADD_ON_INDEX !== -1) {
      // preserve the add-on "id" - these are present for add-ons already saved to a booking
      // they're required to be able to remove them
      if (UPDATED_ADDONS[ADD_ON_INDEX].id) {
        ADD_ON.id = UPDATED_ADDONS[ADD_ON_INDEX].id;
      }

      UPDATED_ADDONS[ADD_ON_INDEX] = ADD_ON;
    } else {
      UPDATED_ADDONS.push(ADD_ON);
    }

    return UPDATED_ADDONS;

    // const ADD_ON = {
    //   db_addon_id: data.addOnID,
    //   option_id: data.optionID,
    //   traveller_id: data.travellerID,
    //   _destroy: destroy,
    // };

    // const UPDATED_ADDONS = [
    //   ...addOns,
    // ];

    // UPDATED_ADDONS.push(ADD_ON);

    // return UPDATED_ADDONS;
  }

  getUpdateTravellers(addOns, data, destroy) { // eslint-disable-line
    const ADD_ON_INDEX = addOns
      .findIndex(addOn => addOn.db_addon_id === data.addOnID
        && addOn.option_id === data.optionID
        && addOn.traveller_id === data.travellerID);

    // Create a new add-on
    const ADD_ON = {
      db_addon_id: data.addOnID,
      option_id: data.optionID,
      traveller_id: data.travellerID,
      _destroy: destroy,
    };

    const UPDATED_ADDONS = [
      ...addOns,
    ];

    // Find or replace the add-on
    if (ADD_ON_INDEX !== -1) {
      // preserve the add-on "id" - these are present for add-ons already saved to a booking
      // they're required to be able to remove them
      if (UPDATED_ADDONS[ADD_ON_INDEX].id) {
        ADD_ON.id = UPDATED_ADDONS[ADD_ON_INDEX].id;
      }

      UPDATED_ADDONS[ADD_ON_INDEX] = ADD_ON;
    } else {
      UPDATED_ADDONS.push(ADD_ON);
    }

    return UPDATED_ADDONS;
  }

  /**
   * Get the updated add-ons
   * @param addOns
   * @param data
   * @param destroy
   * @returns {...*[]}
   */
  getUpdateAddOns(addOns, data, destroy) {
    const { dispatch } = this.props;

    // find another add on on the same day
    const SAME_DAY_BOOKING = addOns
      .find(addOn => !addOn._destroy // eslint-disable-line
        && addOn.day_id === data.dayID
        && addOn.traveller_id === data.travellerID);

    if (SAME_DAY_BOOKING && !destroy) {
      dispatch({
        type: 'MESSAGES_ADD',
        payload: {
          id: `invalid-add-on-selected-${data.dayID}`,
          type: 'error',
          content: 'Sorry, you can only add 1 activity per day per traveller to your tour.',
        },
      });
      return addOns;
    }

    if (SAME_DAY_BOOKING && destroy) {
      dispatch({
        type: 'MESSAGES_REMOVE',
        payload: `invalid-add-on-selected-${data.dayID}`,
      });
      // return addOns;
    }

    const ADD_ON_INDEX = addOns
      .findIndex(addOn => addOn.day_id === data.dayID
        && addOn.db_addon_id === data.addOnID
        && addOn.option_id === data.optionID
        && addOn.traveller_id === data.travellerID);

    // Create a new add-on
    const ADD_ON = {
      day_id: data.dayID,
      db_addon_id: data.addOnID,
      option_id: data.optionID,
      traveller_id: data.travellerID,
      option_price: data.optionPrice,
      _destroy: destroy,
    };

    const UPDATED_ADDONS = [
      ...addOns,
    ];

    // Find or replace the add-on
    if (ADD_ON_INDEX !== -1) {
      // preserve the add-on "id" - these are present for add-ons already saved to a booking
      // they're required to be able to remove them
      if (UPDATED_ADDONS[ADD_ON_INDEX].id) {
        ADD_ON.id = UPDATED_ADDONS[ADD_ON_INDEX].id;
      }

      UPDATED_ADDONS[ADD_ON_INDEX] = ADD_ON;
    } else {
      UPDATED_ADDONS.push(ADD_ON);
    }

    return UPDATED_ADDONS;
  }

  /**
   * Handle save of add ons
   * @param action
   */
  handleSave(action = 'save') {
    const { addOns, travellers, updatingAddon } = this.state;
    const { user, booking, dispatch } = this.props;
    const UPDATED_TRAVELLERS = [
      ...travellers,
    ];

    // copy the add-ons across to the travellers (required by API)
    UPDATED_TRAVELLERS.map((trav) => {
      const NEW_TRAV = trav;
      if (!Array.isArray(NEW_TRAV.addons)) NEW_TRAV.addons = [];
      const ADDONS = addOns.filter(ad => ad.traveller_id === trav.id);
      NEW_TRAV.addons = [
        ...ADDONS,
      ];
      return NEW_TRAV;
    });
    this.setState({
      saving: true,
    }, async () => {
      const UPDATED_BOOKING = {
        travellers: UPDATED_TRAVELLERS,
        is_with_gear: document.getElementById('isWithGear') ? document.getElementById('isWithGear').checked : false,
      };

      const UPDATE = await UPDATE_BOOKING(
        booking.currentBooking.id,
        user.session.token,
        user.session.email,
        UPDATED_BOOKING,
      );

      if (!updatingAddon) {
        dispatch({
          type: 'MESSAGES_ADD',
          payload: {
            id: 'update-booking',
            type: UPDATE.success ? 'success' : 'error',
            content: UPDATE.success ? 'Your booking has been updated' : 'Failed to update your booking.',
          },
        });

        setTimeout(() => {
          dispatch({
            type: 'MESSAGES_REMOVE',
            payload: 'update-booking',
          });
        }, 5000);
      }

      if (UPDATE && UPDATE.success) {
        if (action === 'save') {
          await GET_BOOKING(dispatch, booking.currentBooking.id, user.session.token, user.session.email);
        } else {
          navigate(`${PAGES.BOOKINGS_ESSENTIALS.PATH}?bookingId=${booking.currentBooking.id}&tour=${booking.currentBooking.tour_slug}`);
        }
      }

      this.setState({
        saving: false,
        updatingAddon: false,
      });
    });
  }

  /**
   * Update add ons
   * @param data
   */
  updateAddOns(data) {
    const { addOns } = this.state;
    let UPDATED_ADDONS = addOns;
    data.forEach((item) => {
      UPDATED_ADDONS = this.getUpdateAddOns(UPDATED_ADDONS, item.data, item.destroy);
    });

    this.setState({
      addOns: UPDATED_ADDONS,
      updatingAddon: true,
    }, () => this.handleSave('save'));
  }

  updateEssential(data) {
    const { addOns } = this.state;
    let UPDATED_ADDONS = addOns;
    data.forEach((item) => {
      UPDATED_ADDONS = this.getUpdateEssentials(UPDATED_ADDONS, item.data, item.destroy);
    });

    this.setState({
      addOns: UPDATED_ADDONS,
      updatingAddon: true,
    }, () => this.handleSave('save'));
  }

  updateTravellers(data) {
    this.setState({
      travellers: [data.data.traveller],
    }, () => this.handleSave('save'));
  }

  /**
   * Filter activities
   * @param list
   * @returns {*}
   */
  filterList(list) {
    const { values } = this.state;
    const filteredList = list;
    const FILTER_KEYS = Object.keys(values).filter(key => values[key]);
    return filteredList.filter((act) => {
      const MATCHES = [];
      FILTER_KEYS.forEach((key) => {
        const ACT_VALUE = act[FIELDS[key].MAP];
        const FILTER_VALUE = values[key];
        if (
          (ACT_VALUE === FILTER_VALUE)
          || (ACT_VALUE.includes(FILTER_VALUE))
          // Days use in array
          || (key === FIELDS.TourDays.ID && ACT_VALUE.includes(parseInt(values[key], 10)))
        ) {
          MATCHES.push(true);
        }
      });
      return FILTER_KEYS.length === MATCHES.length;
    });
  }

  filterOptions(activity, day) {
    const { booking } = this.props;
    const arrOptions = [];
    activity.options.forEach((option) => {
      if (DATE_IN_BETWEEN(booking.currentBooking.departure_date, day.ordinal_number, option.available_from, option.available_to)) {
        arrOptions.push(option);
      }
    });
    return arrOptions;
  }

  render() {
    const { booking, user } = this.props;
    const {
      activities,
      values,
      travellers,
      formattedActivities,
      formattedEssentials,
      addOns,
      // showAllActivities,
      loading,
      saving,
    } = this.state;
    let dayOptions = [];
    let isDisabled = false;
    if (booking.currentBooking) {
      isDisabled = daysFromNow(booking.currentBooking.departure_date) < 60;
      const NUM_DAYS = DAYS_BETWEEN(booking.currentBooking.departure_date, booking.currentBooking.end_date);
      dayOptions = Array.from(Array(NUM_DAYS).keys()).map(day => (
        {
          name: `Day ${day + 1}`,
          id: day + 1,
          value: day + 1,
        }
      ));
    }

    const USER_FAVS = formattedActivities.filter(act => user.favorites.activities.includes(act.api_id));
    const NO_FAVS = formattedActivities.filter(act => !user.favorites.activities.includes(act.api_id));
    const FILTERS_SELECTED = Object.values(values).some(val => val);
    const NO_FAVS_FILTERED = FILTERS_SELECTED ? this.filterList(NO_FAVS) : NO_FAVS;

    const locationOptions = [];
    NO_FAVS.forEach((act) => {
      const OPTION = {
        name: act.location,
        id: act.location,
        value: act.location,
      };
      if (!locationOptions.find(option => option.id === act.location)) {
        locationOptions.push(OPTION);
      }
    });

    return (
      <BookingLayout page="bookings/add-ons">
        <div className={`l-two-col ${saving ? 'essential-is-saving' : ''}`}>
          <BookingHeader
            title="On-Road Activities"
            subTitle="The below activities are available for you to book through your driver when you’re in NZ and on tour with us."
          />
          <div>
            {isDisabled && (
              <div className="c-notice">
                <h5 className="c-heading c-heading--h5">
                  Please note as you are travelling less than 60 days out, your account is now locked.
                  <br />
                  <br />
                  If you wish to add any activities or make any upgrades contact the team at <a className="no-effect-a" href="mailto: info@hakatours.com">info@hakatours.com</a> and
                  they will see if they can facilitate. Otherwise add-ons will happen on tour.
                </h5>
              </div>
            )}
            {USER_FAVS.length
              ? (
                <div className="u-card">
                  <h3 className="c-heading c-heading--h3">Add Your Favourite Activities</h3>
                  <div className="l-booking-favourites">
                    <div className="l-booking-favourites__grid">
                      {USER_FAVS.map(activity => activity.booking_tour_days.map(day => (
                        <BookingActivity
                          key={day.api_id}
                          day={day}
                          activity={activity}
                          booking={booking}
                          // options={this.filterOptions(activity, day)}
                          options={activity.options}
                          images={activities.images.edges}
                          bookingAddOns={addOns}
                          travellers={travellers}
                          update={this.updateAddOns}
                          isDisabled={isDisabled}
                        />
                      )))}
                    </div>
                  </div>
                </div>
              ) : null
            }
            {booking.currentBooking?.tour_name.includes("Snow") 
              ? (
                <div className="u-card">
                  <h3 className="c-heading c-heading--h3">Add on activities</h3>
                  <div className="l-booking-add-ons">
                    <p>Would you like to add ski/snowboard gear hire or lessons to this booking? These extras can be reserved now and paid for directly to the mountain team when on the tour.</p>
                    <p>Please see a list of ski & snowboard gear and clothing available for hire by <a class="click-here" href='/pdf/SNOW_SAFARI_add-ons.pdf' target='_blank'>clicking here</a> and tick this box if you would like to add gear hire to your booking. Our team will be in touch shortly to discuss further details and to confirm your request.</p>
                    <form action="">
                      <div className="l-form-group l-form-group--checkbox">
                        <FormInput
                          id="isWithGear"
                          name="isWithGear"
                          type="checkbox"
                          modifier="c-form-control--checkbox"
                        />
                        <FormLabel inputID="isWithGear" title="Add gear?" />
                      </div>
                    </form>
                  </div>
                </div>
              ) : null
            }
            <div className="u-card">
              <h3 className="c-heading c-heading--h3">ALL ACTIVITIES CAN BE BOOKED DIRECTLY WITH YOUR DRIVER WHILST ON TOUR AND YOU CAN PAY THE ACTIVITY OPERATOR DIRECTLY</h3>
              <div className="l-booking-add-ons">
                <div className="l-booking-add-ons__filter">
                  <form action="">
                    <Select
                      label="Types"
                      placeholder="All types"
                      id={FIELDS.TourCategory.ID}
                      name={FIELDS.TourCategory.ID}
                      options={activities.categories}
                      value={values[FIELDS.TourCategory.ID]}
                      onChange={this.onFilterChange}
                      srOnly
                    />
                    <Select
                      label="Locations"
                      placeholder="All locations"
                      id={FIELDS.TourLocation.ID}
                      name={FIELDS.TourLocation.ID}
                      options={locationOptions}
                      value={values[FIELDS.TourLocation.ID]}
                      onChange={this.onFilterChange}
                      srOnly
                    />
                    <Select
                      label="Days"
                      placeholder="All days"
                      id={FIELDS.TourDays.ID}
                      name={FIELDS.TourDays.ID}
                      options={dayOptions}
                      value={values[FIELDS.TourDays.ID]}
                      onChange={this.onFilterChange}
                      srOnly
                    />
                  </form>
                </div>
                <div className="l-booking-add-ons__list">
                  <div className="l-grid l-grid--3">
                    {NO_FAVS_FILTERED.map(activity => activity.booking_tour_days.map(day => (
                      <BookingActivity
                        key={day.api_id}
                        day={day}
                        activity={activity}
                        booking={booking}
                        // options={this.filterOptions(activity, day)}
                        options={activity.options}
                        images={activities.images.edges}
                        bookingAddOns={addOns}
                        travellers={travellers}
                        update={this.updateAddOns}
                        isDisabled={isDisabled}
                      />
                    )))}
                  </div>
                  {loading && (
                    <div className="loading-screen">
                      <ClipLoader
                        sizeUnit="px"
                        size={50}
                        color="#123abc"
                      />
                    </div>
                  )}
                  {!loading && !NO_FAVS_FILTERED.length
                    ? (
                      <div>
                        <h4 className="c-heading c-heading--h4 u-text--center">
                          No matching activities
                        </h4>
                        <p className="u-text--center">
                          If you would like to add on additional activities that are not included in your tour, please contact us directly to book at <a href="mailto: info@hakatours.com">info@hakatours.com</a>
                        </p>
                      </div>
                    ) : null
                }
                </div>
              </div>
            </div>
          </div>
          <div>
            {booking.currentBooking ? <BookingSummary /> : null}
          </div>
        </div>
        <BookingToolbar saving={saving}>
          {
            (booking.currentBooking && booking.currentBooking.agree_with_the_conditions)
              ? (
                <Link
                  to={`${PAGES.BOOKINGS_REVIEW.PATH}?bookingId=${booking.currentBooking.id}&tour=${booking.currentBooking.tour_slug}`}
                  className="c-button c-button--border u-hide-screen-large-up"
                >
                  Summary
                </Link>
              ) : null
          }
          <button
            onClick={(e) => {
              e.preventDefault();
              this.handleSave('save');
            }}
            type="button"
            value="Save"
            className="c-button c-button--border"
          >Save
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              this.handleSave('next');
            }}
            type="button"
            className="c-button c-button--primary c-button--wide"
          >Next
          </button>
        </BookingToolbar>
      </BookingLayout>
    );
  }
}

BookingsAddOnsPage.propTypes = {
  booking: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export const query = graphql`
  query {
    allTourPages (
      filter: {
                hidden_category: { eq: false },
              }
    ){
      edges {
        node {
          id
          api_id
          addon_ids
          optional_addon_ids
          days {
            api_id
            ordinal_number
          }
          reverse_days {
            api_id
            ordinal_number
          }
        }
      }
    }

    allActivityCategories {
      edges {
        node {
          id
          api_id
          name
          absolute_slug
          picture_id
        }
      }
    }

    allActivityPages(
      filter: {
        guide_app_only: { ne: true },
      }
    ) {
      edges {
        node {
          id
          api_id
          name
          location
          destination_name
          absolute_slug
          sliding_first_picture_id,
          sliding_second_picture_id,
          sliding_third_picture_id,
          sliding_fourth_picture_id,
          sliding_fifth_picture_id,
          day_ids
          addon_category_ids
          options {
            id
            api_id
            name
            description
            price
            hidden
            archived
            seasonal_pricings {
              id
              api_id
              price
              available_from
              available_to
              hidden
            }
          }
        }
      }
    }

    allActivityImages {
      edges {
        node {
          api_id
          imgix_url
        }
      }
    }
  }
`;

export default connect(mapStateToProps)(BookingsAddOnsPage);
